<template>
  <div>
  </div>
</template>

<script>

export default {
  name: 'Say',
  data () {
    return {

    }
  },

  components: {},

  computed: {},

  beforeMount () {},

  mounted () {},

  methods: {},

  watch: {}

}

</script>
<style lang='' scoped>

</style>
