<template>
  <el-form
    v-if="formModel"
    :rules="formRules"
    :model="formModel"
    ref="formModel"
    label-width="100px"
    label-suffix="："
    @submit.native.stop.prevent="handleFormSubmit('formModel')"
  >
    <el-form-item
      label="角色名称"
      prop="name"
    >
      <el-col :md="9">
        <el-input
          v-model.trim="formModel.name"
          placeholder="例如:manager  (此处最好采用英文字母)"
        >
        </el-input>
      </el-col>
    </el-form-item>

    <el-form-item
      label="描述"
      prop="description"
    >
      <el-col :md="9">
        <el-input
          v-model.trim="formModel.description"
          placeholder="例如:经理"
        >
        </el-input>
      </el-col>
    </el-form-item>

    <el-col :md="17">
      <div
        class="b-tips"
        style="margin-left:100px;"
      >注:角色等级数值越大，角色的级别更高。（最高数值为：9,数值为0时，角色不可登录后台）</div>
    </el-col>

    <el-form-item
      label="角色等级"
      prop="level"
    >
      <el-col :md="9">
        <el-input
          type="number"
          :min="0"
          v-model.trim="formModel.level"
          placeholder=""
        >
        </el-input>
      </el-col>
    </el-form-item>

    <el-form-item label="权限列表">
      <el-col :md="24">
        <div
          class="group-box"
          v-for="(items,index) in zh"
          :key="index"
        >
          <div class="group-title">
            <el-checkbox
              :indeterminate="items.isIndeterminate"
              v-model="items.selectAll"
              @change="handleCheckAllChange(index,items.selectAll)"
            >{{items.name}}</el-checkbox>
          </div>
          <el-checkbox-group
            v-model="items.selectArr"
            @change="handleCheckedCitiesChange(index,items.selectArr)"
          >

            <el-tooltip
              placement="top"
              v-for="(item,key) in items.role"
              :label="item[0]"
              :key="key"
            >
              <span slot="content">{{item[2]}}</span>
              <el-checkbox :key="key" :label="item[0]">
                {{item[1]}}
              </el-checkbox>
            </el-tooltip>
          </el-checkbox-group>
        </div>
      </el-col>
    </el-form-item>
    <el-form-item>
      <el-button
        size="medium"
        type="primary"
        native-type="submit"
        :loading="submitLoading"
      >{{ submitText }}</el-button>
    </el-form-item>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="60%"
    >
      <span>这是一段信息</span>
      <readme />
    </el-dialog>
  </el-form>
</template>

<script>
import readme from './components/readme'
export default {
  components: { readme },
  props: {
    submitText: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    admin: {
      type: Object
    }
  },
  async mounted () {
    this.formModel = Object.assign({}, this.admin.role)
    this.zh = Object.assign({}, this.admin.zh)
    this.admin.zh.forEach((element, index) => {
      if (element.selectArr.length > 0) {
        this.zhSelect[index] = element.selectArr
      }
    })
  },
  data () {
    return {
      dialogVisible: false,
      submitLoading: false,
      formRules: {
        description: [
          {
            required: true,
            message: '请输入角色描述',
            trigger: 'blur'
          }
        ],
        name: [
          {
            required: true,
            message: '请输入角色名称',
            trigger: 'blur'
          }
        ]
      },
      zh: [],
      zhSelect: [],
      formModel: null
    }
  },
  methods: {
    // 全选
    handleCheckAllChange (index, val) {
      this.zhSelect[index] = val ? this.zh[index].role_two : []
      this.zh[index].selectArr = val ? this.zh[index].role_two : []
      this.zh[index].isIndeterminate = false
    },
    handleCheckedCitiesChange (index, val) {
      this.zhSelect[index] = val
      let checkedCount = this.zh[index].selectArr.length
      this.zh[index].selectAll =
                checkedCount === this.zh[index].role.length
      this.zh[index].isIndeterminate =
                checkedCount > 0 && checkedCount < this.zh[index].role.length
    },
    handleFormSubmit (formName) {
      this.formModel.zhSelect = this.zhSelect
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return false
        }

        this.submitLoading = true

        this.$emit(
          'on-submit',
          this.formModel,
          () => {
            this.$refs[formName].clearValidate()
          },
          () => {
            this.submitLoading = false
          }
        )
      })
    }
  }
}
</script>

<style lang="scss">
.group-box {
    margin: 40px 0;
    .group-title {
        border-bottom: 1px solid #f5f5f5;
        .el-checkbox__label {
            font-size: 17px;
            font-weight: 700;
            color: #222;
        }
    }
}
</style>
