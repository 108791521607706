<template>
    <div class="box">
    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>
    <role-form v-if="admin"
                :submit-text="pageTitle"
                :admin="admin"
                :is-edit="true"
                @on-submit="editRole">
    </role-form>
  </div>
</template>

<script>

import RoleForm from '@admin/views/role/_EditForm'
import RoleService from '@admin/services/AdminService'
import flatry from '@admin/utils/flatry'
export default {
  components: {
    RoleForm
  },
  data () {
    return {
      pageTitle: '编辑角色',
      admin: null
    }
  },
  async created () {
    const { data } = await flatry(RoleService.editRole(this.$router.currentRoute.query.id))
    if (data) {
      this.admin = data.data
    }
  },
  methods: {
    async editRole (admin, success, callback) {
      const { data } = await flatry(RoleService.editRole(this.$router.currentRoute.query.id, admin))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({ path: '/role/update', query: { id: data.data } })
        await flatry(this.$store.dispatch('auth/authentication'))
        success()
      }

      callback()
    }
  }
}
</script>

<style scoped>

</style>
